@charset "utf-8";
/**
   _____                 _     _        
  / ____|               | |   | |       
 | |        __ _   ___  | |_  | |   ___ 
 | |       / _` | / __| | __| | |  / _ \
 | |____  | (_| | \__ \ | |_  | | |  __/
  \_____|  \__,_| |___/  \__| |_|  \___|
 ----------------------------------------
 * Castle's Bangumi Stylesheet
 * Last Update: 2020/03/25 [0.9.4]
 * Author: ohmyga (https://ohmyga.cn)
 * GitHub: https://github.com/ohmyga233/castle-Typecho-Theme/
 * LICENSE: GPL V3 (https://www.gnu.org/licenses/gpl-3.0.html)
 **/

.mdui-theme-layout-dark .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-subtitle {
  color: rgba(255,255,255,0.65);
}

.moe-bangumi-page-card .moe-card-content {
  margin-top: 20px;
  padding: 10px 30px 10px 30px;
}

.moe-bangumi-page-card .moe-bangumi-card {
  height: 250px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-left {
  float: left;
  width: 188px;
  height: 100%;
  position: absolute;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-left .moe-bangumi-cover-pc,
.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-left .moe-bangumi-cover-pe {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right {
  float: left;
  margin-left: 208px;
  width: calc(100% - 208px);
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header {
  width: 90%;
  padding: 20px 0 16px 0;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-title,
.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-subtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-title {
  font-size: 24px;
  line-height: 36px;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-subtitle {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right main.moe-bangumi-content {
  font-size: 14px;
  padding-right: 15px;
}

.moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right footer.moe-bangumi-footer {
  right: 0;
  bottom: 0;
  position: absolute;
  padding: 0px 20px 15px 0px;
}

.moe-bangumi-load-more {
  text-align: center;
  margin: 15px 0px 10px;
}

.moe-bangumi-load-more .moe-bangumi-loadBtn {
  transition: all 0.15s;
  animation: rotate360 1s linear infinite;
  -webkit-animation: rotate360 1s linear infinite;
}

@media(max-width: 768px) {
 .moe-bangumi-page-card .moe-bangumi-card {
  height: 110px;
 }

 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-left {
  width: 110px;
 }

 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-left .moe-bangumi-cover-pc {
  display: none;
 }

 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right {
  margin-left: 128px;
  width: calc(100% - 128px);
 }

 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header {
  padding: 10px 0 8px 0;
 }
 
 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-title {
  font-size: 18px;
 }

 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right header.moe-bangumi-header .moe-bangumi-subtitle {
  font-size: 14px;
 }

 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right main.moe-bangumi-content,
 .moe-bangumi-page-card .moe-bangumi-card .moe-bangumi-right footer.moe-bangumi-footer {
  display: none;
 }
}

@media(max-width: 600px) {
 .moe-bangumi-page-card .moe-card-content {
  padding: 10px 15px 10px 15px;
 }
}